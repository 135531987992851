import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { graphql } from '@octokit/graphql';
import { formatDistanceToNow } from 'date-fns';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0d1117;
  color: #c9d1d9;
  min-height: 100vh;
`;

const LoginButton = styled.button`
  background-color: #238636;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #2ea043;
  }
`;

const PRSection = styled.div`
  margin: 20px 0;

  h2 {
    color: #c9d1d9;
    border-bottom: 1px solid #30363d;
    padding-bottom: 8px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #161b22;
  border-bottom: 2px solid #30363d;
  color: #c9d1d9;
  font-weight: 600;
  font-size: 14px;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #30363d;
  font-size: 14px;
  color: #c9d1d9;
`;

const PRLink = styled.a`
  color: #58a6ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #79c0ff;
  }
`;

const PRAuthor = styled.span`
  color: #8b949e;
`;

const CommentCount = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #8b949e;
`;

const SortableHeader = styled(Th)`
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-right: 24px;

  &:after {
    content: '${props => props.$sortDirection === 'asc' ? '↑' : props.$sortDirection === 'desc' ? '↓' : ''}';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b949e;
  }

  &:hover {
    background-color: #1c2129;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #30363d;
  border-radius: 50%;
  border-top: 3px solid #238636;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #8b949e;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    color: #c9d1d9;
  }
`;

const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #21262d;
  border: 1px solid #30363d;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  color: #c9d1d9;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #30363d;
    border-color: #8b949e;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  svg {
    animation: ${props => props.loading ? 'spin 1s linear infinite' : 'none'};
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const RefreshIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M8 3a5 5 0 0 1 4.546 2.914.5.5 0 0 0 .908-.417A6 6 0 0 0 8 2C5.201 2 2.872 3.757 2.186 6.244a.5.5 0 1 0 .956.291C3.708 4.389 5.67 3 8 3z"/>
    <path d="M8 13a5 5 0 0 1-4.546-2.914.5.5 0 0 0-.908.417A6 6 0 0 0 8 14c2.799 0 5.128-1.757 5.814-4.244a.5.5 0 1 0-.956-.291C12.292 11.611 10.33 13 8 13z"/>
  </svg>
);

function App() {
  const [token, setToken] = useState(localStorage.getItem('github_token'));
  const [prs, setPRs] = useState({
    authored: [],
    directReview: [],
    teamReview: [],
    mentioned: []
  });
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    authored: { field: null, direction: null },
    directReview: { field: null, direction: null },
    teamReview: { field: null, direction: null },
    mentioned: { field: null, direction: null }
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const newToken = urlParams.get('token');

    if (newToken) {
      localStorage.setItem('github_token', newToken);
      setToken(newToken);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const fetchPRs = useCallback(async () => {
    setLoading(true);
    const graphqlWithAuth = graphql.defaults({
      headers: {
        authorization: `token ${token}`,
      },
    });

    try {
      const query = `
        query {
          viewer {
            login
            organizations(first: 10) {
              nodes {
                name
                teams(first: 10) {
                  nodes {
                    name
                  }
                }
              }
            }
          }
          authoredPRs: search(query: "is:pr is:open author:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews {
                  totalCount
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
              }
            }
          }
          reviewRequestedPRs: search(query: "is:pr is:open review-requested:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews(first: 10) {
                  nodes {
                    author {
                      login
                    }
                    submittedAt
                    state
                  }
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
                reviewRequests(first: 10) {
                  nodes {
                    requestedReviewer {
                      ... on Team {
                        name
                      }
                      ... on User {
                        login
                      }
                    }
                  }
                }
              }
            }
          }
          mentionedPRs: search(query: "is:pr is:open mentions:@me -author:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews {
                  totalCount
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
              }
            }
          }
        }
      `;

      const result = await graphqlWithAuth(query).catch(e => {
        console.error('GraphQL error:', e);
        return e.data;
      });

      const userTeams = result.viewer.organizations.nodes
        .filter(org => org?.teams)
        .flatMap(org => org?.teams?.nodes?.map(team => team.name));

      const processPRs = (prs) => {
        return prs.map(pr => ({
          ...pr,
          unresolvedThreads: pr.reviewThreads.nodes.filter(thread => !thread.isResolved).length,
          totalComments: (pr.comments?.totalCount || 0) + (pr.reviews?.totalCount || 0)
        }));
      };

      const processReviewPRs = (prs) => {
        const directReview = [];
        const teamReview = [];

        prs.nodes.forEach(pr => {
          const teamNames = pr.reviewRequests.nodes
            .map(request => request.requestedReviewer?.name)
            .filter(name => name);

          const userReview = pr.reviews.nodes
            .filter(review => review.author.login === result.viewer.login)
            .sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt))[0];

          const lastReview = userReview
            ? `${formatDistanceToNow(new Date(userReview.submittedAt))} ago (${userReview.state.toLowerCase()})`
            : 'Never';

          const prWithReview = {
            ...pr,
            teamNames,
            lastReview,
            lastReviewDate: userReview ? userReview.submittedAt : null
          };

          if (teamNames.some(team => userTeams.includes(team))) {
            teamReview.push(prWithReview);
          } else {
            directReview.push(prWithReview);
          }
        });

        return { directReview, teamReview };
      };

      const { directReview, teamReview } = processReviewPRs(result.reviewRequestedPRs);

      const authoredPRs = processPRs(result.authoredPRs.nodes);
      const allReviewPRs = [...directReview, ...teamReview];

      const mentionedPRs = processPRs(result.mentionedPRs.nodes).filter(pr =>
        !authoredPRs.some(authored => authored.id === pr.id) &&
        !allReviewPRs.some(reviewed => reviewed.id === pr.id)
      );

      setPRs({
        authored: authoredPRs,
        directReview: processPRs(directReview),
        teamReview: processPRs(teamReview),
        mentioned: mentionedPRs
      });
    } catch (error) {
      console.error('Error fetching PRs:', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchPRs();
    }
  }, [token, fetchPRs]);

  const handleLogin = () => {
    window.location.href = 'https://github-pr-dashboard-worker.jeremy-7bf.workers.dev/api/auth/github';
  };

  const getSortedPRs = (prs, sortConfig) => {
    if (!sortConfig.field) return prs;

    return [...prs].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.field) {
        case 'repository':
          aValue = `${a.repository.owner.login}/${a.repository.name}`;
          bValue = `${b.repository.owner.login}/${b.repository.name}`;
          break;
        case 'title':
          aValue = a.title;
          bValue = b.title;
          break;
        case 'author':
          aValue = a.author.login;
          bValue = b.author.login;
          break;
        case 'age':
          aValue = new Date(a.createdAt);
          bValue = new Date(b.createdAt);
          break;
        case 'updated':
          aValue = new Date(a.updatedAt);
          bValue = new Date(b.updatedAt);
          break;
        case 'comments':
          aValue = a.totalComments;
          bValue = b.totalComments;
          break;
        case 'unresolved':
          aValue = a.unresolvedThreads;
          bValue = b.unresolvedThreads;
          break;
        case 'lastReview':
          aValue = a.lastReviewDate ? new Date(a.lastReviewDate) : new Date(0);
          bValue = b.lastReviewDate ? new Date(b.lastReviewDate) : new Date(0);
          break;
        default:
          return 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (section, field) => {
    setSorting(prev => {
      const newSorting = { ...prev };
      if (prev[section].field === field) {
        // Toggle direction if same field
        newSorting[section] = {
          field,
          direction: prev[section].direction === 'asc' ? 'desc' :
                     prev[section].direction === 'desc' ? null : 'asc'
        };
      } else {
        // New field, start with ascending
        newSorting[section] = { field, direction: 'asc' };
      }
      return newSorting;
    });
  };

  const renderPRTable = (prs, section, includeTeamColumn = false, includeLastReviewColumn = false) => {
    const sortConfig = sorting[section];
    const sortedPRs = getSortedPRs(prs, sortConfig);

    return (
      <Table>
        <thead>
          <tr>
            <SortableHeader
              onClick={() => handleSort(section, 'repository')}
              $sortDirection={sortConfig.field === 'repository' ? sortConfig.direction : null}
            >
              Repository
            </SortableHeader>
            <SortableHeader
              onClick={() => handleSort(section, 'title')}
              $sortDirection={sortConfig.field === 'title' ? sortConfig.direction : null}
            >
              Title
            </SortableHeader>
            <SortableHeader
              onClick={() => handleSort(section, 'author')}
              $sortDirection={sortConfig.field === 'author' ? sortConfig.direction : null}
            >
              Author
            </SortableHeader>
            {includeTeamColumn && (
              <SortableHeader
                onClick={() => handleSort(section, 'team')}
                $sortDirection={sortConfig.field === 'team' ? sortConfig.direction : null}
              >
                Team
              </SortableHeader>
            )}
            <SortableHeader
              onClick={() => handleSort(section, 'age')}
              $sortDirection={sortConfig.field === 'age' ? sortConfig.direction : null}
            >
              Age
            </SortableHeader>
            <SortableHeader
              onClick={() => handleSort(section, 'updated')}
              $sortDirection={sortConfig.field === 'updated' ? sortConfig.direction : null}
            >
              Last Updated
            </SortableHeader>
            <SortableHeader
              onClick={() => handleSort(section, 'comments')}
              $sortDirection={sortConfig.field === 'comments' ? sortConfig.direction : null}
            >
              Comments
            </SortableHeader>
            <SortableHeader
              onClick={() => handleSort(section, 'unresolved')}
              $sortDirection={sortConfig.field === 'unresolved' ? sortConfig.direction : null}
            >
              Unresolved
            </SortableHeader>
            {includeLastReviewColumn && (
              <SortableHeader
                onClick={() => handleSort(section, 'lastReview')}
                $sortDirection={sortConfig.field === 'lastReview' ? sortConfig.direction : null}
              >
                Your Last Review
              </SortableHeader>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedPRs.map((pr) => (
            <tr key={pr.id}>
              <Td>{`${pr.repository.owner.login}/${pr.repository.name}`}</Td>
              <Td>
                <PRLink href={pr.url} target="_blank" rel="noopener noreferrer">
                  {pr.title} (#{pr.number})
                </PRLink>
              </Td>
              <Td>
                <PRAuthor>{pr.author.login}</PRAuthor>
              </Td>
              {includeTeamColumn && (
                <Td>
                  {pr.teamNames.join(', ')}
                </Td>
              )}
              <Td>{formatDistanceToNow(new Date(pr.createdAt))} ago</Td>
              <Td>{formatDistanceToNow(new Date(pr.updatedAt))} ago</Td>
              <Td>
                <CommentCount>
                  {pr.totalComments}
                </CommentCount>
              </Td>
              <Td>{pr.unresolvedThreads}</Td>
              {includeLastReviewColumn && <Td>{pr.lastReview}</Td>}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const handleRefresh = () => {
    fetchPRs();
  };

  if (!token) {
    return (
      <Container>
        <LoginButton onClick={handleLogin}>
          Login with GitHub
        </LoginButton>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>GitHub PR Dashboard</h1>
        <RefreshButton
          onClick={handleRefresh}
          disabled={loading}
          {...(loading && { 'data-loading': true })}
        >
          <RefreshIcon /> {loading ? 'Refreshing...' : 'Refresh PRs'}
        </RefreshButton>
      </Header>

      {loading ? (
        <LoadingOverlay>
          <LoadingSpinner />
          <div>Fetching Pull Requests...</div>
        </LoadingOverlay>
      ) : (
        <>
          <PRSection>
            <h2>Your Pull Requests</h2>
            {renderPRTable(prs.authored, 'authored')}
          </PRSection>

          <PRSection>
            <h2>Review Requested Directly</h2>
            {renderPRTable(prs.directReview, 'directReview', false, true)}
          </PRSection>

          <PRSection>
            <h2>Review Requested to Your Teams</h2>
            {renderPRTable(prs.teamReview, 'teamReview', true, true)}
          </PRSection>

          <PRSection>
            <h2>Mentioned You</h2>
            {renderPRTable(prs.mentioned, 'mentioned')}
          </PRSection>
        </>
      )}
    </Container>
  );
}

export default App;
